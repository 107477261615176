@import 'styles/mixins';

.menuBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $card-bg-color;
  width: 100vw;
 
}
