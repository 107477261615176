@import 'styles/mixins';

.container {
  width: 100%;
  margin: 8px 0 30px 0;
}

.arrow {
  background: none !important;
  color: $text-color !important;
  font-size: 20px;
  width: 20px;
  height: 20px;

  &::after, &::before {
    content: none !important;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
}

.headerBrowse {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 38px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
}

.title {
  text-transform: capitalize;
  flex: 1;
}

.arrows {
  display: flex;
  margin-top: 0;
  z-index: 2;
}

.hidden {
  display: none;
}


