@import "styles/mixins";

.container {
  z-index: 999;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $card-bg-color;
  box-shadow: -1px 0 8px rgba(0, 0, 0, 0.3);
  @include xs-to-md {
    width: 100%;
    padding: 0 4px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  max-width: 380px;
  @include xs-to-sm {
    width: 100%;
  }
}


.hidden {
  display: none;
}

.prev {
  padding: 5px;
}

.next {
  z-index: 999;
  margin-left: auto;
  width: 100px;
  span {
    margin: 0;
  }
}


