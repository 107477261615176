@import 'styles/mixins';

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
}

.imageRounded {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
}

@keyframes fade {
  from {
    filter: blur(32px);
  }
  to {
    filter: blur(0);
  }
}


.loaded {
  .image {
    animation: fade 0.5s ease-in-out;
  }
}

.notLoaded {
  opacity: 0;
}

.placeholder {
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  object-position: center center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $highlight-bg-color;
  border-radius: 10px;
}
