@import 'styles/mixins';

.container {
  @include custom-scroll;
  overflow-y: scroll;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 60px;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.containerIos {
  @include custom-scroll;
  overflow-y: scroll;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 60px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.content {
  flex: 1;
  width: 100%;
  margin-top: 80px;
}
