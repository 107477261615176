@import 'styles/mixins';
.container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.upperContainer{
  width: 100%;
  height: 100%;
}

.middleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lowerContainer{
  width: 100%;
  height: 100%;
}

.select{
  width: 100%;
  z-index:999;
  display: flex;
  flex-direction: row;
}

.selectItem {
  width:50%;
  min-width: 250px;
}

.onlyDesktop {
  @include xs-to-sm {
    display: none;
  }
    padding-bottom: 70px;
}
