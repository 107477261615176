@import "styles/mixins";

.shareHolder {
  display: flex;
  flex-direction: column;
}

.shareButton {
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 0;
  border-radius: 16px;
  background-color: $card-bg-color;
  border: 1px solid $border-color;
}

.shareText {
  font-size: 20px;
  justify-content: center;
  align-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  color: $text-color;
}

.copyButton {
  user-select: text;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 8px;
  }
}
