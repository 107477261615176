@import "styles/mixins";
.wrapper {
  width: 100%;
  padding: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.howLong {
  margin-top: 20px;
  display: flex;
}

.input {
  background-color: $card-bg-color;
  border-radius: 16px;
  height: 30px;
  color: $text-color;
}
.field {
  width: 90%;
  height: 50px;
  font-size: 16px;
  background-color: $card-bg-color;
  border: 0 solid white;
  border-radius: 10px;
  transition: 0.1s ease-in;
  margin-top: 10px;
  color: $text-color;
  text-indent: 10px;
}

.field:focus {
  border: 1px solid #3cafeb;
}

.recentList {
  @include roboto-medium;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: $white;
  button {
    text-transform: capitalize;
    background: $primary-color;
    border-radius: 16px;
    padding: 8px;
    margin: 8px;
  }
  @include xs-to-sm {
    button {
      display: none;
    }
    button:first-child, button:nth-child(2) {
      display: block;
    }
  }
}
