@import 'styles/mixins';
.header{
  
}

.page {
   display: flex;
   flex-direction: column;
  
  }
  