@import 'styles/mixins';

.input {
  flex: 1;
  color: $text-color;
  height: 100%;
  padding-left: 8px;
  min-width: 40%;
  font-size: 16px;
  background: none;
  border: none;
  line-height: 100%;
}

.filled {
  background: $highlight-bg-color;
  border-radius: 16px;
  border: none;
}

.inputContainer {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;

  &:not(.filled) {
    border-bottom: 1px solid $border-color;
  }
}

.innerContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.tag {
  background: rgba(128, 208, 250, 0.35);
  font-size: 12px;
  padding: 2px 6px;
  text-align: center;
  border-radius: 16px;
  margin: 2px 4px;
}

.resetButton {
  cursor: pointer;
}

.searchIcon {
  font-size: 24px;
}
