@import 'styles/mixins';

.expanded {
  width: 100%;

  @include md-to-xl {
    height: 130px;

  }
  outline: none;
   box-shadow: none;
   -webkit-tap-highlight-color: transparent;
   display: flex;
   flex-direction: row;

}

.navbarFixed {
  z-index: 3;
  height: 72px;

  @include xs-to-md {
    height:52px;
  }

  @include xs {
    height:52px;
  }
  
  width: 100%;
  outline: none;
   box-shadow: none;
   -webkit-tap-highlight-color: transparent;
   display: flex;
   flex-direction: row;
  
   
   -webkit-box-shadow: $header-shadow 0 6px 4px -6px;
   -moz-box-shadow: $header-shadow 0 6px 4px -6px;
   box-shadow: $header-shadow 0 6px 4px -6px;
   justify-content: space-between;
align-content: center;





}
.navbarFixedDetail {
  z-index: 3;
  height: 72px;

  @include xs-to-md {
    height:52px;
  }

  @include xs {
    height:52px;
  }
  
  width: 100%;
  outline: none;
   box-shadow: none;
   -webkit-tap-highlight-color: transparent;
   display: flex;
   flex-direction: row;
  
   
   -webkit-box-shadow: $header-shadow 0 6px 4px -6px;
   -moz-box-shadow: $header-shadow 0 6px 4px -6px;
   box-shadow: $header-shadow 0 6px 4px -6px;
   justify-content: space-between;
align-content: center;

@include xs-to-md{
  display: none;
}



}

.navbarFixedIos {
  height: 58px;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  outline: none;
   box-shadow: none;
   -webkit-tap-highlight-color: transparent;
   display: flex;
   flex-direction: row;
   -webkit-box-shadow: $header-shadow 0 6px 4px -6px;
   -moz-box-shadow: $header-shadow 0 6px 4px -6px;
   box-shadow: $header-shadow 0 6px 4px -6px;
   justify-content: space-between;
align-content: center;


}

.actions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 4px 16px;
  flex: 1;
  transition: all 0.3s;
  transition-timing-function: ease-out;
  @include md-to-lg {
    max-width: 650px;
  }
  @include lg-to-xl {
    max-width: 650px;
  }
  outline: none;
   box-shadow: none;
   -webkit-tap-highlight-color: transparent;


}

.actionIcon {
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: 0 8px;
  fill: white;
}

.editSearch {
  width: calc(100vw - 102px);
 
  max-width: 600px;
  max-height: 48px;
  background: $card-bg-color;
  border-radius: 10px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  transition: all 0.3s;
  transition-timing-function: ease-out;
  color: $sub-text-color;

  &.exp {
    width: 100%;
    max-height: 150px;
    height: fit-content;
  }
} 

.editSearchBlue {
  width: calc(100vw - 102px);
 //font-size: 24px;
  max-height: 48px;
  background: #1697dc;
  border-radius: 10px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  transition: all 0.3s;
  transition-timing-function: ease-out;
  color: $sub-text-color;

  &.exp {
    width: 100%;
    max-height: 150px;
    height: fit-content;
  }
}

.editSearchBlueText{
  margin-left: 10px;
  color: white;
}

.tabIcon{
  color:white;
}

.editSearchTrackless {
  width: calc(100vw - 72px);
  @include xs-to-sm {
    width: calc(100vw - 16px);
  }
  //max-width: 600px;
  max-height: 48px;
  background: $card-bg-color;
  border-radius: 10px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
 
  flex-wrap: nowrap;
  transition: all 0.3s;
  transition-timing-function: ease-out;
  color: $sub-text-color;
 
 
  box-shadow: $header-box-shadow;
  &.exp {
    width: 100%;
    max-height: 150px;
    height: fit-content;
 
  }

 
}

.withBack {
  width: calc(100vw - 102px);
}

.searchIcon, .clearIcon {
  font-size: 24px;
  flex: 0 0 auto;
  margin-top: 4px;
  margin-bottom: 4px;
  align-self: flex-start;
  color: #3cafeb;

}

.clearIcon {
  &:hover {
    background: $highlight-bg-color;
    border-radius: 50%;
  }
}

.query {
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
  &.exp {
    flex-wrap: wrap;
    .prefix {
      @include xs-to-sm {
        display: inline;
      }
    }
  }
  .prefix {
    @include xs-to-lg {
      display: none;
    }
  }
}

.bell {
  margin-top: 3px;
}

.fromLink {
  margin: 5px;
  padding: 16px;

  font-size: 18px;
  text-align: start;
  background: $primary-color;
  color: $text-color;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 24px;
  }

  transition: height 0.3s;
  transition-timing-function: ease-out;
}

.expandedElements {
  margin-left: 12px;
  display: flex;
  z-index: 3;
  flex-direction: column;
  position: absolute;
  transition: all 0.3s;
  transition-timing-function: ease-out;
  @include md-to-xl {
    flex-direction: row;
  }

}

.expandedElementsHidden {
  margin-left: 12px;
  position: absolute;
  display: flex;
  transition: all 0.3s;
  transition-timing-function: ease-out;
  @include md-to-xl {
    flex-direction: row;
  }
}


.headerLogo{
  
  height: 100%;
 
  height: 46px;
  width: 142px;
 
  margin-bottom: 10px;
  background-size: cover;
  background-image: $logo;
  margin-right: 10px;
  @include xs-to-sm {
    display: none;
  }

}

.headerLink {
  @include xs-to-sm {
    display: none;
  }
}

.upperHeader{

  display: none;
  @include xs-to-sm {
    display: block;
    display: none;
  }
}


.navigator {
  
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0px;
  padding-right: 20px;
  height: 100%;
  transition: all 0.3s;
  transition-timing-function: ease-out;
  @include xs-to-sm {
   
    display: none;
  }

  max-width: 400px;
  @include xs-to-md {
    display: none;
  }

}

.navigatorItem{
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  color: $disabled-primary-color;
 
  display: flex;
  flex-direction: column;
  align-items: center;


  &.active {
    color:#3cafeb;
    //text-shadow:0px 0px 5px rgba(60,175,235,0.2);
    span {


      svg {

        fill: #3cafeb;
        //filter: drop-shadow(0px 0px 5px #3cafeb);
        //-webkit-filter: drop-shadow(0px 0px 5px #3cafeb);

      }
    }
  }
  

 // background-color: aquamarine;
}


.sideHolder{
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-around;
  transition: all 0.3s;
  transition-timing-function: ease-out;

  @include md-to-lg {
    //background-color: greenyellow;
    width: 400px;
  }
  @include lg-to-xl {
    //background-color: aquamarine;
    width: 800px;
  }

}


.text{
  color: white;
}

.startText {
  padding-left: 10px;
}

