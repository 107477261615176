@import "styles/mixins";

.wrapper {
  margin: 20px 10px;
  border-bottom: 2px solid $border-color;
  padding-bottom: 10px;
  p {
    margin: 4px 0;
    color: $sub-text-color;
    @include roboto-light;
  }
}

.content {
  padding-top: 4px;
}

