@use 'sass:color';

@import 'styles/mixins';
.sheetHeader {
  background-color: $navbar-color;
}


.container {
  :global(.react-modal-sheet-container) {
    background-color: $navbar-color !important;
    z-index: 4;
  }
}

:global(.react-modal-sheet-backdrop) {
  background-color: rgba(255, 255, 255, 0.3) !important;
  z-index: 3;
}

.closeSheet {
  position: absolute;
  right: 8px;
  top: 40px;
  font-size: 32px;
  z-index: 5;
}

.content {
  @include custom-scroll;
  margin: 0 auto 0 auto;
  padding: 8px 16px;
  max-width: 800px;
  height: calc(100% - 60px);
  overflow-y: auto;
  position: relative;
}
