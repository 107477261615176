@import 'styles/mixins';

.layout {
  background: $bg-color;
  min-height: 100vh;
}

.flexBox {
  display: flex;
  flex-direction: row;
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.noHeader {
    margin-top: 80px;
  }
}

.sidebar {
  @include custom-scroll;
  padding: 48px 24px;
  width: $sidebar-width;
  overflow-y: scroll;
  max-height: 90vh;
  position: sticky;
  top: 80px;


  @include xs-to-md {
    display: none;
  }
}

.container {
  margin: 24px;
  width: 600px;
  @include xl {
    width: 810px;
  }
  @include xs-to-md {
    width: 100%;
  }
}

.content {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  //background-color: goldenrod;
  //max-width: 1700px;

}


.contentHolder{
  @include custom-scroll;
  //background-color: red;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  
  flex: 1;
}
