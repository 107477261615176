@import "styles/mixins";

.container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $navbar-color;
  box-shadow: -1px 0 8px rgba(0, 0, 0, 0.3);
  @include xs-to-sm {
    width: 100%;
  }
}

.content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 50%;
  max-width: 380px;
  @include xs-to-sm {
    width: 100%;
    padding: 0 16px;
  }
}


.next {
  width: 100px;
}


