@mixin light-theme {
  --header-sub: url('https://ik.imagekit.io/7zlqc1cmihe/headersub.png?updatedAt=1636304207203');
  --hamburger: url("https://ik.imagekit.io/7zlqc1cmihe/navigatelight_osrUek7cGCs.svg?updatedAt=1631366480076");
  --logo: url("../../assets/images/logo-colored-dark.svg");
  --logo-highlighted: url("https://ik.imagekit.io/stmedia/new-style-dark-highlighted_JkYLr5Fh7.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649974038033");
  --filter: url("https://ik.imagekit.io/7zlqc1cmihe/Vector-1_nOHA36teX.svg?updatedAt=1631228520701");
  --saved: url("https://ik.imagekit.io/7zlqc1cmihe/Group-2_D0zCOL25i.svg?updatedAt=1631228520746");
  --circle: url("https://ik.imagekit.io/7zlqc1cmihe/circleedit_9_q8p7yt7b.svg?updatedAt=1629286595546");
  --bell: url("https://ik.imagekit.io/7zlqc1cmihe/belledit_bpnuI1-uGa.svg?updatedAt=1629286595477");
  --backpack: url("https://ik.imagekit.io/7zlqc1cmihe/backpack_WhntvpOxYc.svg?updatedAt=1629412156833");
  --calendar: url("https://ik.imagekit.io/7zlqc1cmihe/calendaredit_FkubfbVH4.svg?updatedAt=1629286595546");
  --calendar-white: url("https://ik.imagekit.io/7zlqc1cmihe/whitecalendar_H-aSRT9SjH6.svg?updatedAt=1631486504468");
 
  --color-logo-text: #0e2c49;
  --color-navbar-border: #ecf3f5;
  --color-navbar: #ffffff;
  --color-card-bg: #f3f3f4;
  --color-text: #2e2e2e;
  --disabled-color-text: #6e6e6e;
  --color-sub-text: #9f9ea6;
  --color-actions: rgb(80,80,80);
  --color-active-text: #3cafeb;
  --color-text-inverted: #fefefe;
  --color-sub-text-inverted: #e0e0e0;
  --color-highlight-bg: #e0e0e0;
  --color-border: #e0e0e0;
  --color-border-alternative: #a0a0af;
  --color-primary: #18b2f8;
  --color-primary-light: #6495ce;
  --color-primary-disabled: #9292B0;
  --color-bg: #ffffff;
  --color-purple: #393E55;
  --filter-navbar: blur(100px);
  --color-navbar-text: #000000;
  --color-tabbar: #ffffff;
  --shadow-text: 0px 4px 10px #bdbdbd;
  --cell-highlighter: 0px 2px 2px 5px rgba($color: #3cafeb, $alpha: 0.4);
  --color-warning: #c04444;
  --card-content: #ffffff;
  --card-content-recommendation: #f3f3f4;
  --card-content-browse: #f3f3f4;
  --header-shadow: rgb(0 0 0 / 16%);
  --header-box-shadow: rgba(9, 30, 66, 0.40) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  --browse-tab-color:rgb(146,147,175);
  --browse-card-bg: #484f59;
}
