@mixin dark-theme {
  --header-sub: url('https://ik.imagekit.io/rfjpogvdn8p/headersubddark_oef5-IAVTW.png?updatedAt=1640560123194');
  --hamburger: url("https://ik.imagekit.io/7zlqc1cmihe/navigatedark_lR9JW691R.svg?updatedAt=1631366480088");
  --logo: url("../../assets/images/logo-colored.svg");
  --logo-highlighted: url("https://ik.imagekit.io/stmedia/new-style-dark-highlighted_JkYLr5Fh7.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649974038033");
  --filter: url("https://ik.imagekit.io/7zlqc1cmihe/Vector_XtA12anpI.svg?updatedAt=1631228520690");
  --saved: url("https://ik.imagekit.io/7zlqc1cmihe/Group-3_S3MyC8M6x.svg?updatedAt=1631228520711");
  --circle: url("https://ik.imagekit.io/7zlqc1cmihe/lightcircleedit_xMRSIRFl5.svg?updatedAt=1629315661340");
  --bell: url("https://ik.imagekit.io/7zlqc1cmihe/lightbelledit_nazmTUMRwk.svg?updatedAt=1629315661331");
  --backpack: url("https://ik.imagekit.io/7zlqc1cmihe/lightbackpack_7_ePEvmV6.svg?updatedAt=1629412156777");
  --calendar: url("https://ik.imagekit.io/7zlqc1cmihe/lightcalendaredit_WyMEb8aUo.svg?updatedAt=1629315661303");
  --calendar-white: url("https://ik.imagekit.io/7zlqc1cmihe/whitecalendar_H-aSRT9SjH6.svg?updatedAt=1631486504468");

 
  --color-logo-text: #ffffff;
  --color-tabbar: #292934;
  --color-navbar: #161b22;
  --color-navbar-border: #161b22;
  --color-bg: #000000;
  --color-purple: #AEB9EA;
  --color-card-bg: #161d27;
  --color-text: #fefefe;
  --disabled-color-text: #b0b0b0;
  --color-sub-text: #e0e0e0;
  --color-actions: #e0e0e0;
  --color-active-text: #3cafeb;
  --color-text-inverted: #2e2e2e;
  --color-sub-text-inverted: #404040;
  --color-highlight-bg: #2c2c30;
  --color-border: #1f1f23;
  --color-border-alternative: #4f4f53;
  --color-primary: #3cafeb;
  --color-primary-light: #6495ce;
  --color-primary-disabled: #9292B0;
  --filter-navbar: blur(100px);
  --color-navbar-text: #ffffff;
  --shadow-text: 0px 4px 8px rgba(62, 60, 90, 0.8);
  --cell-highlighter: 0px 2px 2px 5px rgba($color: #3cafeb, $alpha: 0.4);
  --color-warning: #c04444;
  --card-content: #25262c;
  --card-content-recommendation: #181d26;
  --card-content-browse: #000000;
  --header-shadow: rgb(255 255 255 / 16%);
  --header-box-shadow: rgba(255, 255, 255, 0) 0px 4px 12px;
  --browse-tab-color:#b6b6b6;
  --browse-card-bg: #161d27;
}
