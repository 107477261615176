@import 'styles/mixins';

.menuItem {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 20px;
  color: $white;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  

  span {
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;

    > svg {
      fill: $disabled-primary-color;
      transition: 0.5s;
    }

    background-color: transparent;
  }

  &.active {
    span {
      //background-color: $light-primary-color;
      //fill: #3cafeb;

      svg {
        //fill: $white;
        fill: #3cafeb;
        filter: drop-shadow(0px 0px 5px #3cafeb);
        -webkit-filter: drop-shadow(0px 0px 5px #3cafeb);

      }
    }
  }
}

