@import 'styles/mixins';

.container {
  @include roboto-medium;

  position: relative;

  cursor: pointer;
  font-size: 20px;
  user-select: none;
  background-color: transparent;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  margin: 6px;
  border: 1px solid $alternative-border-color;
  color: white;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.checked {
  background: linear-gradient(to right, #FEAC5E 0%, #C779D0 50%, #4BC0C8 100%);
  border: 1px solid #4BC0C8;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border-radius: 8px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid $alternative-border-color;
  background-color: transparent;
  border-radius: 6px;

}

.labelContainer {
  display: inline-block;
  white-space: break-spaces;
  text-align: center;
  align-items: center;
}
