@import 'styles/mixins';

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  align-items: center;
  justify-content: center;

  svg {
    width: 81px;
    height: 61px;
    animation: up-and-down 1s ease-in-out infinite;
    fill: $primary-color;
  }
}

.logoShadow {
  display: block;
  background: radial-gradient(50% 50% at center center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  height: 25px;
  width: 50px;
  margin-left: 5px;
  animation: grow 1s ease-in-out alternate infinite;
}

@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}


@keyframes up-and-down {
  0% {
    transform: translateY(-25px); 
  }

  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}
