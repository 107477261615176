@import 'styles/mixins';

.content {
    display: flex;
}

.container {

    height: 400px;
    padding: 8px 8px 50px 8px;
    max-height: 40vh;
    @include xs-to-sm {
        padding: 0;
        max-height: unset;
    }
}


.valueContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;


}

.valueHolder{
    
    
    margin-left: 35px;
    margin-right: 10px;
}