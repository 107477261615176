@import 'styles/mixins';
$title-size: 80px;

.page {
  
  flex: 1;
  
  padding: 0 2px 48px 8px;
  width: 100%;
  @include xs-to-sm {
    justify-content: center;
  }
  justify-content: center;
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;

}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $card-content-browse;
  width: 100%;
  //max-width: 332px;
  border-radius: 16px;
  height: 362px;
}

.list {
  width: 100%;
  margin: 0;
  display: grid;
  gap: 26px;
  @include xs {
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  }
  @include xs-to-sm {
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  }
  @include md {
    grid-template-columns: repeat( 2, 1fr );
  }
  @include md-to-xl {
    grid-template-columns: repeat( 2, 1fr );
  }

  @include xl{
    grid-template-columns: repeat( 3, 1fr )
  }

  padding: 8px 0;
  justify-items: center;
  align-items: center;
  
}



.cardRecent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $card-content-browse;
  width: 100%;
  max-width: 332px;
  border-radius: 16px;
  height: 332px;
  border-radius: 16px;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid $border-color;
  position: relative;
  background: black;
  margin-left: 8px;
}

.card {
  border-radius: 16px;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid $border-color;
  position: relative;
  background: black;
}

.image {
  width: 100%;
  height: 100%;
  //margin-bottom: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
    
  }
  background-color: $browse-card-bg;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 30px;
  width: 100%;
  //background-color: blueviolet;
}

.title {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  @include xs-to-sm {
    font-size: 28px;
  }
  height: $title-size;
  width: 100%;
  color: $white;
  height: 100%;
  padding: 16px 12px;
  justify-content: center;
  & > span {
    @include roboto-regular;
  }
}

.query {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  width: fit-content;
  padding: 0 8px;
}


.tabs {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 10px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  
  flex-direction: row;
  justify-content: flex-start;
  
  &::-webkit-scrollbar{
    display: none;
  }
  &::-webkit-scrollbar-thumb{
    display: none;
  }

  &::-webkit-scrollbar-track{
    display: none;
  }

 
}

.tabItem {
  @include roboto-medium;
  background: transparent;
  font-size: 14px;
  padding: 0px 2px;
  max-height: 50px;
  text-align: center;
  border-radius: 16px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  flex: 0 0 auto;
  
  padding-left: 0px;
  padding-right: 30px;

  

  @include xs {
    font-size: 14px;
    margin: 2px;
    padding: 4px 2px;
    flex-wrap: wrap;
  }

  &.active {
    color: rgb(64,176,233);
    //background: $card-content-recommendation;
    //background-image: -webkit-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -o-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -moz-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
   // background-image: linear-gradient(90deg, #62b7dc, #ca67db, #df6967);

    .tabIcon {
      //color: #f3f3f4;
      color: rgb(64,176,233);
      pointer-events: none;
      
    }

    .tabName {
      color: rgb(64,176,233);
      
    }

  }

}

.tabItemLast {
  @include roboto-medium;
  background: transparent;
  font-size: 14px;
  padding: 0px 2px;
  max-height: 50px;
  text-align: center;
  border-radius: 16px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  flex: 0 0 auto;
  
  padding-left: 0px;
  padding-right: 0px;

  

  @include xs {
    font-size: 14px;
    margin: 2px;
    padding: 4px 2px;
    flex-wrap: wrap;
  }

  &.active {
    color: rgb(64,176,233);
    //background: $card-content-recommendation;
    //background-image: -webkit-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -o-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -moz-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
   // background-image: linear-gradient(90deg, #62b7dc, #ca67db, #df6967);

    .tabIcon {
      //color: #f3f3f4;
      color: rgb(64,176,233);
      pointer-events: none;
      
    }

    .tabName {
      color: rgb(64,176,233);
      
    }

  }

}

.tabName {
  @include roboto-regular;
  margin: 2px 2px;
  font-size: 14px;
  line-height: 20px;
  color: $browse-tab-color;
  
  //color: #31afea;
  //display: none;
}

.tabIcon {
  //color: #31afea;
  color: $browse-tab-color;
  font-size: 24px;
  pointer-events: none;
  @include xs {
    display: none;
  }
}

.safetyMargin{
  display: flex;
  background-color: transparent;
  height: 30px;
}