@import 'styles/mixins';

.container {
    padding: 8px;
    height: 500px;
    @include xs-to-sm {
        padding: 0;
        max-height: unset;
    }
}
