@import 'styles/mixins';

.page {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  
  @include xs-to-sm {
    justify-content: center;
  }
}

.title {
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
}

.noItem {
  width: 100%;
}

.notifications {
  @include custom-scroll;
  display: flex;
  //max-width: 386px;586px;
  max-width: 686px;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 100%;
  flex: 1;
  @include xs-to-md {
    padding-bottom: 70px;
    max-width: unset;
  }

  @include xs {
    padding: 4px;
  }
}

.error {
  @extend .recommendations;
  font-size: 20px;
  padding: 32px;

  > * {
    margin: 32px 0;
  }
}

.mapbox {
  background-color: $bg-color;
  flex: 1;
  display: flex;
  @include xs-to-md {
    display: none;
  }
}


.whiteText {
  color: white;
}

.page {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @include xs-to-sm {
    justify-content: center;
  }
}

.title {
  width: 100%;
}

.noItems {
  width: 100%;
}

.recommendations {
  @include custom-scroll;
  display: flex;
  //max-width: 386px;586px;
  max-width: 686px;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 100%;
  flex: 1;
  @include xs-to-md {
    padding-bottom: 70px;
    max-width: unset;
  }

  @include xs {
    padding: 4px;
  }
}

.error {
  @extend .recommendations;
  font-size: 20px;
  padding: 32px;

  > * {
    margin: 32px 0;
  }
}


.recommendationDetails {
  @include custom-scroll;
  display: flex;
  max-width: 686px; //586px
  padding: 0px;
  //padding-left: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 100%;
  flex: 1;
  @include xs-to-md {
    padding-bottom: 70px;
  }
}

.tabs {
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: space-around;

  @include xs-to-sm {
    display: flex;
  }
}

.tabItem {
  @include roboto-medium;
  background: transparent;
  font-size: 14px;
  padding: 0px 2px;
  max-height: 50px;
  text-align: center;
  border-radius: 16px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  flex: 0 0 auto;

  

  @include xs {
    font-size: 14px;
    margin: 2px;
    padding: 4px 2px;
    flex-wrap: wrap;
  }

  &.active {
    color: rgb(64,176,233);
    //background: $card-content-recommendation;
    //background-image: -webkit-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -o-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -moz-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
   // background-image: linear-gradient(90deg, #62b7dc, #ca67db, #df6967);

    .tabIcon {
      //color: #f3f3f4;
      color: rgb(64,176,233);
      pointer-events: none;
      
    }

    .tabName {
      color: rgb(64,176,233);
      transition: all 0.5s ease-out;
    }

  }

}

.tabName {
  @include roboto-regular;
  margin: 2px 2px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(146,147,175);
  transition: all 0.5s ease-out;
  //color: #31afea;
  //display: none;
}

.tabIcon {
  //color: #31afea;
  color: rgb(146,147,175);
  font-size: 24px;
  pointer-events: none;
  @include xs {
    display: none;
  }
}


