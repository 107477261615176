@import 'styles/mixins';

.videoContainer {
  width: 100%;
  height: 110%;
  display: flex;
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  object-fit: cover;
}

.container {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: fadeIn 2s ease-out;
 
  
  z-index: 999;

  svg {
    width: 100px;
    height: auto;
  }

  

  .upperContainer{
    margin-top: max(-100%,-700px);
    z-index: 999;
    
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, $bg-color 50%);
    padding-bottom:20px;
    
    animation: fadeIn 2s ease-out;
    @include sm-to-xl {
      margin-bottom: -25px;
    }
    


  }

  .lowerContainer{
  
    
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    animation: fadeIn 2s ease-out;
    width: 100%;
    

  }

  .logo { 
  height: 90px;
  width: 270px;
  margin: 16px;
  
  background-size: cover;
  background-image: $logo-highlighted;
  @include md-to-lg {
    margin: 16px 8px;
  }
  }

  .motto {
    @include roboto-medium;
    @extend .textShadow;
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  h1 {
    font-size: 48px;
    text-align: center;
  }
  a {
    max-width: 280px;
    font-size: 20px;
    height: 48px;
    border-radius: 32px;
  }

  .login {
    @include roboto-medium;
    font-size: 20px;
    max-width: unset !important;
    height: fit-content;
    > span {
      color: $text-color;
    }
  }

  & > * {
    margin: 16px;
  }
}
.continue {
  @include roboto-medium;
  font-size: 20px;
}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.actionButton {
  background-color: rgb(49, 149, 255);
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color:white;
  margin-top: 10px;
  margin-bottom: 20px;
}

.actionButton2 {
  background-color:rgb(50, 67, 87);
  width: 100%;
  display: flex;
  height: 50px;
  border-radius: 10px;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  line-height: 24px;
  font-weight: 400;
  color:white;
  margin-top: 10px;
  margin-bottom: 30px;
}


.login2 {
  @include roboto-medium;
  font-size: 20px;
  max-width: unset !important;
  margin-bottom: 30px;
  height: fit-content;
  > span {
    color: $text-color;
  }
}