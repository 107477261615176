@import "styles/mixins";

.container {
  @include custom-scroll;
  overflow-y: scroll;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 60px;
  align-items: center;
  display: flex;
  flex-direction: column;
  // background-color: royalblue;
}

.content {
  flex: 1;
  width: 100%;
  max-width: 850px;
  // background-color: saddlebrown;
}
