// Colors
$text-color: var(--color-text);
$disabled-text-color: var(--disabled-color-text);
$logo-text-color: var(--color-logo-text);
$sub-text-color: var(--color-sub-text);
$bg-color: var(--color-bg);
$card-bg-color: var(--color-card-bg);
$active-text-color: var(--color-active-text);
$highlight-bg-color: var(--color-highlight-bg);
$border-color: var(--color-border);
$alternative-border-color: var(--color-border-alternative);
$light-text-color: #fefefe;
$inverted-text-color: var(--color-text-inverted);
$primary-color: var(--color-primary);
$light-primary-color: var(--color-primary-light);
$disabled-primary-color: var(--color-primary-disabled);
$navbar-color: var(--color-navbar);
$navbar-color-border: var(--color-navbar-border);
$navbar-text-color: var(--color-navbar-text);
$tabbar-color: var(--color-tabbar);
$navbar-filter: var(--filter-navbar);
$text-shadow: var(--shadow-text);
$cell-highlighter: var(--cell-highlighter);
$cell-bg-image: var(--cell-bg-image);
$warning-color: var(--color-warning);

$white: #ffffff;
$black: #000000;
$green: #58cf52;
$purple: var(--color-purple);
$sidebar-width: 300px;
$border-radius: 16px;

$card-bg-url: var(--card-bg-url);
$heart-selected: var(--heart-selected);
$heart-not-selected: var(--heart-not-selected);
$calendar: var(--calendar);
$calendar-white: var(--calendar-white);
$backpack: var(--backpack);
$bell: var(--bell);
$circle: var(--circle);
$saved: var(--saved);
$filter: var(--filter);
$logo: var(--logo);
$logo-highlighted: var(--logo-highlighted);
$hamburger: var(--hamburger);
$header-sub: var(--header-sub);
$card-content: var(--card-content);
$card-content-recommendation: var(--card-content-recommendation);
$card-content-browse: var(--card-content-browse);
$actions-color: var(--color-actions);
$header-shadow: var(--header-shadow);
$header-box-shadow: var(  --header-box-shadow);
$browse-tab-color: var(--browse-tab-color);
$browse-card-bg: var( --browse-card-bg);