@import 'styles/mixins';
.holder {
  //background-color: aqua;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
  z-index: 3;
  display: none;
  @include xs-to-md {
    display: flex;
  }
  -webkit-box-shadow: -1px 0 8px $header-shadow;
  -moz-box-shadow: -1px 0 8px $header-shadow;
  box-shadow: -1px 0 8px $header-shadow;
}

.element {
  font-size: 20px;

}
