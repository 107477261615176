@import 'styles/mixins';

.page{
  @include custom-scroll;
  display: flex;
  flex-direction: column;
  
}

.recommendationCard {
  width: 100%;
  min-width: 322px;
  margin-top: 37px;

}

.heart {
  margin-top: 7%;
  margin-right: 10px;
  padding: 2px;
  width: 40px;
  height: 40px;
 // background-image: $heart-not-selected;
  background-repeat: no-repeat;
  display: flex;
  //background-color: $card-content-recommendation;
  border-radius: 20px;
  justify-content: center;
  background-position: center;
  //box-shadow: $text-shadow;

  margin-top: auto;
  margin-bottom: auto;
}

.share {
  margin-top: 7%;

  padding: 2px;
  width: 40px;
  height: 40px;

  background-repeat: no-repeat;
  display: flex;
  //background-color: $card-content-recommendation;
  border-radius: 20px;
  justify-content: center;
  background-position: center;
  //box-shadow: $text-shadow;
  margin-top: auto;
  margin-bottom: auto;
}

.shareIcon{

  margin: auto;
  width: 40px;
  font-size: 23px;
  color: $sub-text-color;
}

.likeIconFilled{

  margin: auto;
  width: 40px;
  font-size: 23px;
  color: rgb(240,119,162);
}
.likeIcon{

  margin: auto;
  width: 40px;
  font-size: 23px;
  color: $sub-text-color;

}

.disclaimer{
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  //background-color:$card-content-recommendation;
  border-radius: 5px;
  width: 100%;


  height: 130px;

  line-height: 20px;


}

.disclaimerIcon{
  display: flex;
 height:40px;
  width: 40px;
  font-size: 30px;
  //color: #3cafeb;
  color: white;
  margin-right: 10px;
background-color: #3cafeb;
border-radius: 5px;



}

.disclaimerIcon2{
  display: flex;
 height:34px;
  width: 34px;
  font-size: 20px;
  //color: #3cafeb;
  color: white;
  margin-right: 10px;
background-color: #3cafeb;
border-radius: 5px;
padding: 4px;


}

.heartFilled {
  margin-right: 3px;
  margin-top: 7%;
  padding: 2px;
  width: 40px;
  height: 40px;
 // background-image: $heart-selected;
  background-repeat: no-repeat;
  display: inline-block;
  background-color: $card-content-recommendation;
  border-radius: 20px;
  justify-content: center;
  background-position: center;
  box-shadow: $text-shadow;
  color: rgb(240,119,162);
  align-content: center;
  align-items: center;
  align-self: center;

  margin-top: auto;
  margin-bottom: auto;
}


.contentElement {
  display: flex;
  flex-direction: row;
  margin-top: 6px;

  margin-bottom: 12px;
  font-size: 15px;
  //background-color: red;
  width: 100%;

}

.contentElement3 {
  display: flex;
  flex-direction: row;
  margin-top: 6px;

  margin-bottom: 12px;
  font-size: 15px;
  //background-color: red;
  width: 100%;
  padding-left: 5px;

}

.justifiedText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 15px;

}

.contentElement2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;

  margin-bottom: 12px;
  font-size: 14px;
}

.elementIcon {

  width: 20px;
  height: 20px;
  margin-top: -6px;
}

.elementIcon5 {

  width: 32px;
  height: 32px;
  margin-top: -10px;
}

.elementIcon2 {

  width: 20px;
  height: 20px;
  margin-right: 16px;
}
.elementIcon3 {
  margin-left: 3px;
  width: 20px;
  height: 20px;
  margin-right: 13px;
}
.elementIconAttraction {

  margin-left: 3px;
  width: 11.67px;
  height: 16.67px;
  margin-right: 20-11.67-3px;

}

.elementText {
  margin-top: 1px;
  line-height: 19px;
  font-size: 15px;
  margin-left: 18px;

  width: 100%;
 // max-width: 250px;

  .ul{
    display: inline
  }
  .li{
    display: inline;
  }
}

.elementHighlight{
  font-weight: bold;
  display: inline-block;
}

.elementHighlight2{
  line-height: 19px;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
}

.elementText3 {
  margin-top: 1px;
  line-height: 23px;
  font-size: 20px;
  margin-left: 18px;

  width: 100%;
 // max-width: 250px;

  .ul{
    display: inline
  }
  .li{
    display: inline;
  }
}

.dang {
  .ul{
    display: inline
  }
  .li{
    display: inline;
  }
}

.elementText2 {

  display: flex;
  flex-direction: row;
  margin-top: 1px;
  line-height: 19px;
  font-size: 15px;

}

.slide {
  padding: 2px;
  margin-bottom: 20px;
  width: 100%;
}

.slide2 {
  padding: 2px;

  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 10px;
}

.slideImageRow {
  
  @include xs-to-md {
    height: 120px;
  }
  height: 200px;
  width: 50%;
}

.sideContent{
  padding-left: 5px;
  
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  

}

.eventClick{
  
  width: 100%;
}


.sideContent2{
  padding-left: 15px;
  
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;



}

.sideContentTitle {

  z-index: 2;
  text-align: left;
  @include xs-to-md {
    font-size: 18px;
    line-height: 20px;
  }

  font-size: 22px;
  line-height: 24px;

  
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: auto;
  margin-bottom: 0px;
  margin-left: 10px;


}

.sideContentSubtitle {

  z-index: 2;
  text-align: left;

  @include xs-to-md {
    font-size: 16px;
    line-height: 19px;
  }
  font-size: 18px;
  line-height: 22px;

  
  margin-top: 3px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  margin-left: 10px;

}

.sideContentSubtitles {

  display:flex;
 flex-direction: column-reverse;
  z-index: 2;
  text-align: left;

  @include xs-to-md {
    font-size: 14px;
    line-height: 19px;
  }
  font-size: 16px;
  line-height: 22px;

  
  margin-top: 3px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;

}





.slideImage {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  @include xs-to-md {
    height: 180px;
  }
}

.slideImageContainer {
  display: flex;
  width: 100%;

}

.slideImageContainer2 {
  display: flex;
  height: 100px;
}

.slideImage2 {
  width: 278px;
  height: 180px;
  border-radius: 15px;
}

.centeredSlide {

  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.slideText {
  @extend .textShadow;
  z-index: 2;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: auto;
  margin-bottom: 0px;


}



.slideText2 {
  @extend .textShadow;
  z-index: 2;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  margin-top: -5px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.slideText3 {
  @extend .textShadow;
  z-index: 2;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin-top: 8px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 38px;
  margin-bottom: 12px;

}

.slideText4 {
  @extend .textShadow;
  z-index: 2;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  margin-top: 0px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 30px;

}

.showDetails {
  margin-top: 17px;
  background-color: #1697dc;
  width: 100%;
  height: 38.27px;
  border-radius: 12px;
  margin-bottom: 17px;
  outline: none;
  color: white;

}


.slideText5 {
  //@extend .textShadow;
  z-index: 2;
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  margin-top: 13px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;

}

.slideText6 {
  //@extend .textShadow;
  z-index: 2;
  text-align: left;
  font-size: 17px;
  line-height: 21px;
  margin-top: 8px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;

}

.tagtext{
  margin-top: 8px;
  color:$bg-color;
  display: flex;
  flex-direction: row;
  
  

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: row;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  
}
.tagtextItem{
  margin-right: 10px;
  background-color: $text-color;
  color:$bg-color;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  width: fit-content;
  text-align: center;
  
}

.tagtextItem2{
  margin-left: 10px;
  margin-right: 10px;
  background-color: $text-color;
  color:$bg-color;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
 width: fit-content;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  
}

.slideText7 {
  background-color: #1697dc;
  z-index: 2;


  
  width: 60px;
  height: 50px;

  display: flex;
 
  justify-content: center;
  align-self: center;
  align-items: center;
  border-radius: 16px;
  color:white;
  margin-right: 10px;
  margin-left: 10px;

}

.slideText9 {
  background-color: #1697dc;
  z-index: 2;


  
  width: 60px;
  height: 50px;

  display: flex;
 
  justify-content: center;
  align-self: center;
  align-items: center;
  border-radius: 16px;
  color:white;
  margin-right: 10px;
  margin-left: 10px;

}


.slideText8 {
  @extend .textShadow;
  z-index: 2;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  margin-top: 3px;
  width: 100%;

  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  color: #3cafeb;

}
.slideText7Holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

 margin-bottom: 20px;


}

.durationText{
  margin-top: 8px;
  margin-bottom: 15px;
  text-align: left;
  font-size: 17px;
  line-height: 21px;
}



.headerImage {
  margin-top: 0px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  object-position: center;

}

.description{

  text-align: left;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 20px;
  
}

.oneRow {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  width: 100%;
  justify-content: space-between;
  
  align-items: center;
  align-content: center;
  align-self: center;
  padding-left: 10px;
  padding-left: 16px;
  padding-right: 16px;
 
    background-color: $bg-color;
    position: sticky;
    top:0;
    z-index: 999;
    -webkit-box-shadow: $header-shadow 0 6px 4px -6px;
   -moz-box-shadow: $header-shadow 0 6px 4px -6px;
   box-shadow: $header-shadow 0 6px 4px -6px;
  
  

}

.headerHolder{
  z-index: 2;
  margin-top: 0px;

  background-color: $bg-color;
  width: 100%;
  //min-height: 130px;

  
 margin-bottom: 10px;

 position: relative;
 
}

.headerHolderCoverless{
  z-index: 2;
  margin-top: 0px;

  width: 100%;

}

.title {

  width: calc(100% - 20px);
  font-size: 24px;
  font-weight: 500;
  
 
}

.report {
  display: flex;
  flex-direction: column;
}


.tabs {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
 
  
 

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  
  flex-direction: row;
  justify-content: flex-start;
  
  &::-webkit-scrollbar{
    display: none;
  }
  &::-webkit-scrollbar-thumb{
    display: none;
  }

  &::-webkit-scrollbar-track{
    display: none;
  }
  

 

 
}

.tabItem {
  @include roboto-medium;
  background: transparent;
  font-size: 14px;
  padding: 0px 2px;
  max-height: 50px;
  text-align: center;
  border-radius: 16px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  flex: 0 0 auto;
  
  padding-left: 0px;
  padding-right: 30px;
  
  

  @include xs {
    font-size: 14px;
    margin: 2px;
    padding: 4px 2px;
    flex-wrap: wrap;
  }

  &.active {
    color: rgb(64,176,233);
    //background: $card-content-recommendation;
    //background-image: -webkit-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -o-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -moz-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
   // background-image: linear-gradient(90deg, #62b7dc, #ca67db, #df6967);

    .tabIcon {
      //color: #f3f3f4;
      color: rgb(64,176,233);
      pointer-events: none;
      
    }

    .tabName {
      color: rgb(64,176,233);
      
    }

  }

}

.tabItemLast {
  @include roboto-medium;
  background: transparent;
  font-size: 14px;
  padding: 0px 2px;
  max-height: 50px;
  text-align: center;
  border-radius: 16px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  flex: 0 0 auto;
  
  padding-left: 0px;
  padding-right: 0px;

  

  @include xs {
    font-size: 14px;
    margin: 2px;
    padding: 4px 2px;
    flex-wrap: wrap;
  }

  &.active {
    color: rgb(64,176,233);
    //background: $card-content-recommendation;
    //background-image: -webkit-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -o-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
    //background-image: -moz-linear-gradient(90deg, #62b7dc, #ca67db, #df6967);
   // background-image: linear-gradient(90deg, #62b7dc, #ca67db, #df6967);

    .tabIcon {
      //color: #f3f3f4;
      color: rgb(64,176,233);
      pointer-events: none;
      
    }

    .tabName {
      color: rgb(64,176,233);
      
    }

  }

}

.tabName {
  @include roboto-regular;
  margin: 2px 2px;
  font-size: 14px;
  line-height: 20px;
  color: $browse-tab-color;
  
  //color: #31afea;
  //display: none;
}

.tabIcon {
  //color: #31afea;
  color: $browse-tab-color;
  font-size: 24px;
  pointer-events: none;
  @include xs {
    display: none;
  }
}


.poiDirect {
  //color: #31afea;
  color: white;
  font-size: 24px;
  pointer-events: none;
  @include xs {
    display: none;
  }
}

.safetyMargin{
  display: flex;
  background-color: transparent;
  height: 30px;
}

.country {

 

  display: flex;
  flex-direction: row;
margin-top: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  padding-left: 8px;

}

.countryElement {
  margin-right: 5px;
}

.holdzo{
 
  padding-left: 10px;
  padding-right: 10px;
}