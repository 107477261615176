@import 'styles/mixins';

.tag {
  background: $primary-color;
  font-size: 16px;
  padding: 8px 4px;
  max-height: 37px;
  text-align: center;
  border-radius: 16px;
  margin: 0 4px 8px 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  flex: 0 0 auto;
  
}

.name {
  margin: 0 4px;
}

.icon {
  color: $white;
}

.removable {
  cursor: pointer;
}
