@import 'styles/mixins';

.container {
  margin: 0;

  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 
  
}
