@import 'styles/mixins';


.monthPicker {
  padding: 0 30px;
  width: 100%;
}
.monthSlide {
  width: 70px !important;
}

.month {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid $border-color;

  &.selected {
    border-color: $primary-color;
    color: $primary-color;

    .icon {
      color: $primary-color;
    }
  }

}

.icon {
  margin: 4px;
  height: 24px;
  width: 24px;
}
